import { Box, Grid, Stack, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import { UI_KIT } from "../../assets";
import { FEATURES } from "../../components/constants";
import theme from "../../configs/theme";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up("xs")]: {
        padding: "40px 0 !important",
      },
      [theme.breakpoints.up("sm")]: {
        padding: "60px 0 !important",
      },
      [theme.breakpoints.up("md")]: {
        padding: "100px 0 !important",
      },
      [theme.breakpoints.up("xl")]: {
        padding: "100px 0 !important",
      },
      [theme.breakpoints.up("lg")]: {
        padding: "100px 0 !important",
      },
    },
    direction: {
      [theme.breakpoints.up("md")]: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
      },
      [theme.breakpoints.up("xl")]: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
      },
      [theme.breakpoints.up("lg")]: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
      },
    },

    title: {
      fontWeight: "700 !important",
      color: "#4A4A4A",
      textAlign: "center",
      [theme.breakpoints.up("xs")]: {
        fontSize: "24px !important",
        lineHeight: "26.52px",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "24px !important",
        lineHeight: "26.52px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "40px !important",
        lineHeight: "44px",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "40px !important",
        lineHeight: "44px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "40px !important",
        lineHeight: "44px",
      },
    },
    content: {
      color: "#4A4A4A",
      [theme.breakpoints.up("xs")]: {
        fontSize: "12px !important",
        lineHeight: "19.2",
        width: "auto !important",
        textAlign: "center",
        // padding: "20px",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "14px !important",
        lineHeight: "22.4px",
        width: "auto !important",
        textAlign: "left",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "16px !important",
        lineHeight: "25.6px",
        width: "590px !important",
        textAlign: "left",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "16px !important",
        lineHeight: "25.6px",
        width: "590px !important",
        textAlign: "left",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "16px !important",
        lineHeight: "25.6px",
        width: "590px !important",
        textAlign: "left",
      },
    },
    subtitle: {
      fontFamily: "open sans !important",
      fontWeight: "600 !important",
      color: "#4A4A4A",
      [theme.breakpoints.up("xs")]: {
        textAlign: "center",
        fontSize: "12px !important",
        lineHeight: "16.34px",
        padding: "0px 0 20px 0",
        textAlign: "center",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "14px !important",
        lineHeight: "19.07px",
        padding: "0 20px",
        textAlign: "left !important",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "16px !important",
        lineHeight: "22px",
        padding: "0px 0 10px 0",
        textAlign: "left !important",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "16px !important",
        lineHeight: "22px",
        padding: "0px 0 10px 0",
        textAlign: "left !important",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "16px !important",
        lineHeight: "22px",
        padding: "0px 0 10px",
      },
    },
    subcontentContainer: {
      [theme.breakpoints.up("xs")]: {
        padding: "0px 0px 10px 0",
      },
      [theme.breakpoints.up("sm")]: {},
      [theme.breakpoints.up("md")]: {},
      [theme.breakpoints.up("xl")]: {},
      [theme.breakpoints.up("lg")]: {},
    },
    subcontent: {
      fontFamily: "open sans !important",
      color: "#4A4A4A",
      fontWeight: "400",
      [theme.breakpoints.up("xs")]: {
        fontSize: "12px !important",
        lineHeight: "16.34px",
      },

      [theme.breakpoints.up("sm")]: {
        fontSize: "14px !important",
        lineHeight: "19.07px",
        textAlign: "left",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "16px !important",
        lineHeight: "22px",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "16px !important",
        lineHeight: "22px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "16px !important",
        lineHeight: "22px",
      },
    },
  })
);

export default function WhyChoose() {
  const classes = useStyles(theme);

  return (
    <Box bgcolor="#F8F8F8">
      <Box sx={{ maxWidth: "1300px", margin: "auto" }}>
        <Box
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
          className={classes.root}
        >
          <Typography className={classes.title}>
            Why choose NowFloats?
          </Typography>

          <Box
            // spacing={2}
            direction={{ xs: "column", sm: "column", md: "row" }}
            className={classes.direction}
            padding={{ xs: "25px 0 20px 0", sm: "", md: "", xl: "", lg: "" }}
          >
            <Box
              flex={0.47}
              justifyContent={"center"}
              alignItems="center"
              // width={{ xs: "300px", sm: window.innerWidth * 0.5 }}
            >
              <Typography className={classes.content}>
                Founded in 2012, NowFloats Technologies Ltd. strives to help
                Indian businesses simplify their digital journey through
                innovation via four products: Boost 360, Zadinga, Kitproxy and
                BizHQ.
              </Typography>
              <Typography className={classes.content} style={{padding:"20px 0"}}>
                With cloud-first and mobile-first products, NowFloats enables
                businesses to harness digital technologies to scale their
                business and run their operations efficiently.
              </Typography>
            </Box>
            <Box flex={1} width={{ xs: "345px", sm: "auto !important" }}>
              <Typography className={classes.subtitle}>
                When it comes to ONDC, NowFloats is:
              </Typography>
              {FEATURES.map((statement, index) => {
                return (
                  <Stack
                    direction={"row"}
                    key={index}
                    sx={{
                      width: { xs: "335px", sm: "750px", md: "590px" },
                      paddingBottom: "12px"
                        // index == 0
                        //   ? {
                        //       sm: "0px !important",
                        //       xs: "12px",
                        //       md: "12px",
                        //       lg: "12px",
                        //     }
                        //   : "12px",
                    }}
                    className={classes.subcontentContainer}
                  >
                    <Box alignSelf={"flex-start"} padding={"4px 12px 0px 0px"}>
                      <img
                        src={UI_KIT.ICONS.check}
                        height={{ xs: "12px", sm: "15px", md: "18px" }}
                        width={{ xs: "12px", sm: "15px", md: "18px" }}
                        style={{ alignSelf: "center" }}
                      />
                    </Box>

                    <Typography className={classes.subcontent}>
                      {statement.title}
                    </Typography>
                  </Stack>
                );
              })}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
