import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { UI_KIT } from "../../assets";
import { HoverEffectButton } from "../../components/HoverEffectButton";

import { createStyles, makeStyles } from "@mui/styles";
import theme from "../../configs/theme";
import { handleWindowResize } from "../../components/utils";

const useStyles = makeStyles((theme) =>
  createStyles({
    customContainerBtn: {
      [theme.breakpoints.up("xs")]: {
        width: "150px !important",
        height: "40px",
      },
      [theme.breakpoints.up("sm")]: {
        width: "180px !important",
        height: "34px !important",
      },
      maxHeight: "50px !important",
      width: "150px !important",
    },
    titleContainer: {
      [theme.breakpoints.up("xs")]: {
        margin: "0px 0 40px 0",
      },
    },
  })
);

export default function ONDC() {
  const classes = useStyles(theme);

  return (
    <Box
      bgcolor={"#F8F8F8"}
      padding={{
        xs: "60px 0 40px 0",
        sm: "100px 20px",
      }}
    >
      <Box maxWidth={"1300px"} margin="auto">
        {/* mobile */}
        {handleWindowResize().isMobile ? (
          <Grid
            spacing={2}
            direction={"row"}
            flexWrap={{ xs: "wrap", sm: "nowrap" }}
            justifyContent={"space-between"}
            padding={"0 0px"}
            sx={{
              justifyContent: "center",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                component="img"
                alt="Logo"
                src={UI_KIT.ondc_logo}
                justifyContent={"center"}
                alignItems="center"
                display={"flex"}
                sx={{
                  width: { xs: "90%" },
                  margin: "",
                  // margin: { xs: "auto 5px 20px 20px", sm: "auto", md: "auto" },
                }}
              />
            </Box>

            <Box
              justifyContent={"center"}
              alignItems={"center"}
              alignSelf={"center"}
              sx={{
                flex: { xs: 1, sm: 1, md: 0.49 },
                marginLeft: { xs: "0px", sm: "40px", md: "0px" },
              }}
            >
              <Typography
                sx={{
                  marginLeft: { xs: "80px", sm: "0px" },
                  marginTop: { xs: "10px", sm: "0px" },
                  paddingBottom: "30px",
                  fontSize: { xs: "24px", sm: "24px", md: "40px" },
                  fontWeight: "700",
                  color: "#4A4A4A",
                }}
              >
                What is ONDC?
              </Typography>
              <Typography
                fontFamily={"open sans"}
                color="#4A4A4A"
                textAlign={{ xs: "center", sm: "center", md: "unset" }}
                lineHeight={{
                  xs: "19.2px",
                  sm: "25.6px",
                  md: "25.6px",
                  lg: "25.6px",
                  xl: "25.6px",
                }}
                sx={{
                  padding: { xs: "0 30px", sm: "0px", md: "0px" },
                  // width: { xs: "335px", sm: "350px", md: "600px" },
                  paddingBottom: { xs: "10px", sm: "30px" },
                  fontSize: { xs: "12px", sm: "16px" },
                }}
              >
                ONDC or Open Network for Digital Commerce is a government-backed
                online marketplace that includes various buyers, sellers and
                logistics providers.
              </Typography>

              <Typography
                fontFamily={"open sans"}
                color="#4A4A4A"
                textAlign={{ xs: "center", sm: "center", md: "unset" }}
                lineHeight={{
                  xs: "19.2px",
                  sm: "25.6px",
                  md: "25.6px",
                  lg: "25.6px",
                  xl: "25.6px",
                }}
                sx={{
                  padding: { xs: "0 30px", sm: "0px", md: "0px" },
                  // width: { xs: "335px", sm: "350px", md: "600px" },
                  paddingBottom: { xs: "10px", sm: "30px" },
                  fontSize: { xs: "12px", sm: "16px" },
                }}
              >
                Currently, any enterprise, retailer or business can get ONDC
                enabled. Once you have joined the network, your products will be
                visible in the search results of other apps.
              </Typography>

              <Box
                marginTop={"1rem"}
                display={"flex"}
                justifyContent={{ xs: "center", sm: "flex-start" }}
                alignItems="center"
                marginLeft={{ xs: "20px", sm: "0px" }}
              >
                <HoverEffectButton
                  url={"https://ondc.org/network-participants/"}
                  title={`Know more`}
                  containerStyle={classes.customContainerBtn}
                />
              </Box>
            </Box>
          </Grid>
        ) : (
          //{/* tab n desktop */}
          <Box
            display={"flex"}
            direction={"row"}
            flex={1}
            justifyContent={"space-evenly"}
            padding={"0 0px"}
          >
            <Box flex={{ sm: 0.5, md: 0.5 }}>
              <Box
                component="img"
                alt="Logo"
                src={UI_KIT.ondc_logo}
                display={"flex"}
                flex={0.2}
                justifyContent={"center"}
                alignItems="center"
                sx={{
                  height: { sm: "198px", md: "359px" },
                  // marginLeft: { xs: "30px", sm: "15px", md: "0px" },
                  // marginBottom: { xs: "30px", sm: "97px", md: "0px" },
                }}
              />
            </Box>

            <Box
              justifyContent={"center"}
              alignItems={"center"}
              alignSelf={"center"}
              sx={{
                flex: { sm: 0.52, md: 0.52, xl: 0.52 },
                marginLeft: { xs: "0px", sm: "20px", md: "0px" },
              }}
            >
              <Typography
                sx={{
                  marginLeft: { xs: "80px", sm: "0px" },
                  marginTop: { xs: "10px", sm: "0px" },
                  paddingBottom: "30px",
                  fontSize: { xs: "24px", sm: "24px", md: "40px" },
                  fontWeight: "700",
                  color: "#4A4A4A",
                }}
              >
                What is ONDC?
              </Typography>
              <Typography
                fontFamily={"open sans"}
                color="#747474"
                textAlign={{ sm: "unset", md: "unset" }}
                lineHeight={{
                  xs: "19.2px",
                  sm: "25.6px",
                  md: "25.6px",
                  lg: "25.6px",
                  xl: "25.6px",
                }}
                sx={{
                  padding: { xs: "0 30px", sm: "0px", md: "0px" },
                  paddingBottom: { xs: "10px", sm: "30px" },
                  fontSize: { xs: "12px", sm: "16px" },
                }}
              >
                ONDC or Open Network for Digital Commerce is a government-backed
                online marketplace that includes various buyers, sellers and
                logistics providers.
              </Typography>
              <Typography
                fontFamily={"open sans"}
                textAlign={{ xs: "center", sm: "unset" }}
                color="#747474"
                sx={{
                  marginTop: { xs: "10px", sm: "0px" },
                  paddingBottom: { xs: "10px", sm: "30px" },
                  fontSize: { xs: "12px", sm: "16px" },
                }}
              >
                Currently, any enterprise, retailer or business can get ONDC
                enabled. Once you have joined the network, your products will be
                visible in the search results of other apps.
              </Typography>
              <Box
                marginTop={"1rem"}
                display={"flex"}
                justifyContent={{ xs: "center", sm: "flex-start" }}
                alignItems="center"
                marginLeft={{ xs: "20px", sm: "0px" }}
              >
                <HoverEffectButton
                  url={"https://ondc.org/network-participants/"}
                  title={`Know more`}
                  containerStyle={classes.customContainerBtn}
                />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}
