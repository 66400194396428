import { Box, Stack, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import theme from "../configs/theme";

const useStyles = makeStyles((theme) => ({
  textfields: {
    marginBottom: "1rem !important",
    fontSize: "14px !important",
    fontFamily: "open sans !important",
    lineHeight: "19.07px !important",
  },
  resize: {
    fontSize: "24px",
    fontFamily: "open sans",
    color: "black",
  },
  labelStyle: {
    color: "#4A4A4A !important",
  },
}));

export default function CustomInput({
  value,
  onChange,
  label,
  isDirty,
  isValid,
  field,
  onBlur,
  onFocus,
  type,
  inputProps,
  multiline,
}) {
  const classes = useStyles(theme);
  return (
    <Stack>
      <TextField
        id="standard-basic"
        error={isDirty && isValid === false}
        label={label}
        InputLabelProps={classes.labelStyle}
        inputProps={inputProps}
        InputProps={{
          classes: {
            input: classes.resize,
          },
        }}
        maxRows={4}
        multiline={multiline && multiline === true ? true : false}
        type={type || "text"}
        variant="standard"
        size="small"
        value={value}
        className={classes.textfields}
        style={{
          padding: "0.2rem 0",
        }}
        sx={{
          fontSize: "14px !important",
          fontFamily: "open sans !important",
          lineHeight: "19.07px !important",
          width: { xs: "275px", sm: "275px", lg: "275px", xl: "275px" },
          // height: { xs: "30px", sm: "30px" },
          margin: "auto",
        }}
        onChange={(e) => onChange(e)}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    </Stack>
  );
}
