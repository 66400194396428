import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { UI_KIT } from "../../assets";
import { handleWindowResize } from "../../components/utils";
import { createStyles, makeStyles } from "@mui/styles";
import theme from "../../configs/theme";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up("xs")]: {
        margin: "auto",
        padding: "0 20px",
      },
      [theme.breakpoints.up("sm")]: {
        padding: "0 20px !important",
      },
      [theme.breakpoints.up("md")]: {
        padding: "0 20px !important",
      },
      [theme.breakpoints.up("xl")]: {
        padding: "0 20px !important",
      },
      [theme.breakpoints.up("lg")]: {
        padding: "0 20px !important",
      },
    },
    title: {
      [theme.breakpoints.up("xs")]: {
        padding: "45px 0 30px 0",
      },
      [theme.breakpoints.up("sm")]: {},
      [theme.breakpoints.up("md")]: {
        padding: "100px 0",
      },
      [theme.breakpoints.up("xl")]: {
        padding: "100px 0",
      },
      [theme.breakpoints.up("lg")]: {
        padding: "100px 0",
      },
    },
    content: {
      textAlign: "center",
      fontFamily: "open sans !important",
      color: "#4A4A4A",
      fontWeight: "400",
      [theme.breakpoints.up("xs")]: {
        lineHeight: "19.2px",
        padding: "23px 0 40px 0",
        fontSize: "12px !important",
      },
      [theme.breakpoints.up("sm")]: {
        lineHeight: "22.4px",
        padding: "30px 0 60px 0",
        fontSize: "14px !important",
      },
      [theme.breakpoints.up("md")]: {
        lineHeight: "32px",
        padding: "57px 0 100px 0",
        fontSize: "16px !important",
      },
      [theme.breakpoints.up("xl")]: {
        lineHeight: "32px",
        padding: "60px 0 100px 0",
        fontSize: "16px !important",
      },
      [theme.breakpoints.up("lg")]: {
        lineHeight: "32px",
        padding: "60px 0 100px 0",
        fontSize: "16px !important",
      },
    },
  })
);

export default function HowONDCWorks() {
  const classes = useStyles(theme);

  const ONDC_WORKING_NORMAL = UI_KIT.ondc_working;
  const ONDC_WORKING_MOBILE = UI_KIT.ondc_working_mobile;
  const ONDC_WORKING_TABLET = UI_KIT.ondc_working_tablet;

  return (
    <Box sx={{ maxWidth: "1300px", margin: "auto" }}>
      <Box
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
        className={classes.root}
      >
        <Typography
          textAlign={"center"}
          fontSize={{ xs: "24px", sm: "40px" }}
          fontWeight="700"
          color="#000000"
          className={classes.title}
        >
          How ONDC works
        </Typography>
        <Stack
          flex={1}
          spacing={2}
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent={"center"}
          display={"flex"}
        >
          <Box
            component="img"
            src={
              handleWindowResize().isDesktop
                ? ONDC_WORKING_NORMAL
                : handleWindowResize().isMobile
                ? ONDC_WORKING_MOBILE
                : ONDC_WORKING_TABLET
            }
            justifyContent={"center"}
            alignItems="center"
            display={"flex"}
            width={{
              xs: "100%",
              // sm: "727px",
              // md: "511px",
              // xl: "auto",
              // lg: "auto",
            }}
            sx={{ marginLeft: { xs: "0px", sm: "0px", md: "0px" } }}
          />
        </Stack>
        <Typography className={classes.content}>
          No matter where your shop is located, ONDC allows you to reach
          customers all across the country.{" "}
        </Typography>
      </Box>
    </Box>
  );
}
