function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

function handleWindowResize() {
  const resolution = window.innerWidth;
  const isMobile = resolution >= 320 && resolution <= 480;
  const isTablet = resolution >= 500 && resolution <= 1197;
  const isDesktop = !isMobile && !isTablet;
  return { resolution, isMobile, isTablet, isDesktop };
}

export { getWindowSize, handleWindowResize };
