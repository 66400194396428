import nowfloats_logo from "./images/nf_logo.svg";
import ondc_connect from "./images/ondc_connect.png";
import ondc_connect_mobile from "./images/ondc_connect_mobile.png";
import zadinga from "./images/zadinga.svg";
import zadinga_logo from "./images/zadinga_logo.svg";
import zadinga_mobile from "./images/zadinga_mobile.svg";
import thank_you from "./images/thanks.svg";
import kitsune_logo from "./images/kitsune_logo.svg";
import kitsune_ondc from "./images/kitsune_ondc.svg";
import kitsune_mobile from "./images/kitsune_mobile.svg";
import new_logo from "./images/new_logo_no_text.svg";
import new_mobile from "./images/mobile.png";
import new_web from "./images/web.svg";
import new_tab from "./images/tab.png";

import ondc_logo from "./images/ondc_logo.svg";
import ondc_working from "./images/ondc_working.svg";
import ondc_working_mobile from "./images/ondc_working_mobile.svg";
import ondc_working_tablet from "./images/ondc_working_tablet.svg";

import girl_with_desktop from "./images/girl_with_desktop.svg";
import ondc_logo_small from "./images/ondc_logo_small.svg";

import bnc from "./icons/bnc.svg";
import electronics from "./icons/electronics.svg";
import fashion from "./icons/fashion.svg";
import kirana from "./icons/kirana.svg";
import rnc from "./icons/rnc.svg";
import check from "./icons/check.svg";
import close from "./icons/close.svg";

export const UI_KIT = {
  nowfloats_logo,
  ondc_connect,
  ondc_connect_mobile,
  kitsune_ondc,
  zadinga,
  ondc_logo,
  ondc_working,
  ondc_working_mobile,
  ondc_working_tablet,
  new_mobile,
  new_tab,
  new_web,
  girl_with_desktop,
  kitsune_mobile,
  zadinga_mobile,
  ondc_logo_small,
  kitsune_logo,
  thank_you,
  zadinga_logo,
  new_logo,
  ICONS: {
    bnc,
    electronics,
    fashion,
    rnc,
    kirana,
    check,
    close,
  },
};
