import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Modal } from "@mui/material";

export default function Loader({ loading }) {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: window.innerWidth * 0.8,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div className="container">
      {loading && (
        <Modal
          open={loading}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box style={modalStyle}>
            <CircularProgress color="warning" />
          </Box>
        </Modal>
      )}
    </div>
  );
}
