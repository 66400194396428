import { Modal, Box, Typography, Stack, FormControl } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { UI_KIT } from "../assets";
import theme from "../configs/theme";
import { regex } from "../utils/validations";
import { BUSINESS_CATEGORIES, BUSINESS_TYPE } from "./constants";
import { CustomButton2 } from "./CustomButton2";
import CustomDropdown from "./CustomDropdown";
import CustomInput from "./CustomInput";
import Loader from "./Loader";
import "./connectForm.css";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  divider: {
    borderColor: "#D6D6D6 !important",
    marginTop: "auto !important",
    marginBottom: "auto !important",
    height: "95%  !important",
    marginLeft: "2rem !important",
    marginRight: "24px !important",
  },
  textfieldsContainer: {},
  textfields: {
    padding: "2rem !important",
  },
  closeIcon: {
    justifyContent: "flex-start",
    alignItems: "flex-start",
    alignSelf: "flex-start",
  },
  customContainerBtn: {
    width: "275px !important",
    margin: "auto !important",
    top: "13px !important",
  },
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "335px", sm: "335px" },
    height: { xs: "100%", sm: "690px" },
    overflow: {
      xs: "scroll",
      sm: "scroll",
      md: "scroll",
      xl: "scroll",
      lg: "scroll",
    },
    paddingLeft: { xs: "12px", sm: "0px" },
    paddingRight: { xs: "12px", sm: "0px" },
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: "30px 0",
    borderRadius: "10px !important",
    border: "1px solid rgba(74, 74, 74, 0.2)",
  },
}));

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "335px", sm: "335px" },
  paddingLeft: { xs: "12px", sm: "0px" },
  paddingRight: { xs: "12px", sm: "0px" },
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "30px 0",
  borderRadius: "10px !important",
  border: "1px solid rgba(74, 74, 74, 0.2)",
  msOverflowStyle: "none",
  scrollbarWidth: "none",
};

export default function ConnectForm({ open, handleClose, businessTypeProp }) {
  const classes = useStyles(theme);

  useEffect(() => {
    if (businessTypeProp) {
      setBusinessType({
        ...businessType,
        value: businessTypeProp,
        isValid: true,
        dirty: false,
      });
    }
  }, [businessTypeProp]);

  const [fullName, setFullName] = useState({
    value: "",
    dirty: false,
    isValid: true,
  });

  const [email, setEmail] = useState({
    value: "",
    dirty: false,
    isValid: true,
  });
  const [phone, setPhone] = useState({
    value: "",
    dirty: false,
    isValid: true,
  });
  const [businessName, setBusinessName] = useState({
    value: "",
    dirty: false,
    isValid: true,
  });

  const [businessType, setBusinessType] = useState({
    value: "",
    dirty: false,
    isValid: true,
  });

  const [customBusinessType, setCustomBusinessType] = useState({
    value: "",
    dirty: false,
    isValid: true,
    isActive: false,
  });

  const [category, setCategory] = useState({
    value: "",
    dirty: false,
    isValid: true,
  });
  const [customCategory, setCustomCategory] = useState({
    value: "",
    dirty: false,
    isValid: true,
    isActive: false,
  });

  const [businessInterest, setBusinessInterest] = useState({
    value: "",
  });

  const [loading, setLoading] = useState(false);

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async () => {
    let isError = false;
    if (
      fullName.value.length === 0 ||
      regex.name.test(fullName.value) == false
    ) {
      setFullName({ ...fullName, isValid: false, dirty: true });
      isError = true;
    }
    if (
      phone.value.length < 10 ||
      regex.phoneNumber.test(phone.value) == false
    ) {
      setPhone({ ...phone, isValid: false, dirty: true });
      isError = true;
    }

    if (email.value.length === 0 || regex.email.test(email.value) == false) {
      setEmail({ ...email, isValid: false, dirty: true });
      isError = true;
    }
    if (businessName.value.length === 0) {
      setBusinessName({ ...businessName, isValid: false, dirty: true });
      isError = true;
    }

    if (category.value.length === 0) {
      setCategory({ ...category, isValid: false, dirty: true });
      isError = true;
    }
    if (businessType.value.length === 0) {
      setBusinessType({ ...businessType, isValid: false, dirty: true });
      isError = true;
    }

    if (category.value === "Other" && customCategory.value.length === 0) {
      setCustomCategory({
        ...customCategory,
        isValid: false,
        dirty: true,
      });
      isError = true;
    }

    if (
      businessType.value === "Other" &&
      customBusinessType.value.length === 0
    ) {
      setCustomBusinessType({
        ...customBusinessType,
        isValid: false,
        dirty: true,
      });
      isError = true;
    }

    if (isError) {
      return;
    } else {
      setLoading(true);
      handleSubmitForm();
    }
  };

  const handleSubmitForm = async () => {
    let JSONBody = {
      name: fullName.value,
      phone: phone.value,
      email: email.value,
      businessName: businessName.value,
      businessCategory: customCategory.value || category.value,
      reason: businessInterest.value,
      businessType: customBusinessType.value || businessType.value,
    };
    let url =
      "https://zpmap637ga.execute-api.ap-south-1.amazonaws.com/default/kit-ondc-leads-generator";
    try {
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(JSONBody),
      });
      setLoading(false);
      if (res.ok) {
        handleClose();
        setIsSubmitted(true);
        setFullName({ ...fullName, value: "" });
        setPhone({ ...phone, value: "" });
        setEmail({ ...email, value: "" });
        setBusinessName({ ...businessName, value: "" });
        setCategory({ ...category, value: "" });
        setCustomCategory({ ...customCategory, value: "" });
        setBusinessType({ ...businessType, value: "" });
        setCustomBusinessType({ ...customBusinessType, value: "" });
      } else {
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      handleClose();
      console.log(e);
    }
  };

  const selectionChangeHandler = (event) => {
    if (event.target.value === "Other") {
      setCustomCategory({ ...customCategory, isActive: true });
    } else {
      setCustomCategory({ ...customCategory, isActive: false, value: "" });
    }
    setCategory({
      ...category,
      value: event.target.value,
      isValid: true,
      dirty: false,
    });
  };
  const selectionChangeHandlerforBusinessType = (event) => {
    if (event.target.value === "Other") {
      setCustomBusinessType({ ...customBusinessType, isActive: true });
    } else {
      setCustomBusinessType({ ...customBusinessType, isActive: false });
    }
    setBusinessType({
      ...businessType,
      value: event.target.value,
      isValid: true,
      dirty: false,
    });
  };

  const handleConfirmSubmitModalClose = () => {
    setIsSubmitted(false);
    handleClose();
  };

  return isSubmitted ? (
    <Modal
      open={isSubmitted}
      onClose={handleConfirmSubmitModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={[modalStyle]}
        bgcolor={"white"}
        maxHeight="auto"
        maxWidth={"95%"}
      >
        <Stack flex={1} justifyContent={"space-between"} alignItems={"center"}>
          <Stack
            flex={1}
            alignSelf={"center"}
            justifyContent={"center"}
            display="flex"
            margin="0 10px"
          >
            <Box
              onClick={handleConfirmSubmitModalClose}
              component="img"
              src={UI_KIT.ICONS.close}
              justifyContent={"center"}
              alignItems="center"
              display={"flex"}
              alignSelf={"flex-end"}
            />

            <Box
              component="img"
              src={UI_KIT.thank_you}
              justifyContent={"flex-start"}
              alignItems="flex-start"
              display={"flex"}
              alignSelf={"center"}
              paddingBottom={"10px"}
              sx={{ width: { xs: "275px", sm: "275px" } }}
            />

            <Box alignSelf={"center"} paddingBottom={"20px"}>
              <Typography
                variant={"h4"}
                // fontSize={12}
                fontFamily={"open sans"}
                color={"#FFB900"}
                fontWeight={"700"}
                textAlign={"center"}
                paddingBottom={"10px"}
              >
                Thank you.
              </Typography>

              <Typography variant={"h6"} fontSize={18} textAlign={"center"}>
                Someone from our team will call you shortly.
              </Typography>
            </Box>
            <Box flex={1} alignSelf={"center"}>
              <CustomButton2
                onClick={handleConfirmSubmitModalClose}
                title={`OK`}
                containerStyle={{ minWidth: "200px !important" }}
              />
            </Box>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  ) : (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle} bgcolor={"white"} style={{}}>
        <Loader loading={loading} />
        <Stack flex={1} justifyContent={"space-between"} alignItems={"center"}>
          <Stack
            flex={1}
            direction={{ xs: "column", sm: "column" }}
            alignSelf={"center"}
            justifyContent={"center"}
            sx={{ width: { xs: "275px", sm: "275px" } }}
          >
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              sx={{
                padding: "0 0 1rem 0",
              }}
            >
              <Box>
                <Typography
                  fontSize={"20px"}
                  lineHeight={"28px"}
                  fontWeight={"700"}
                  fontFamily={"open sans"}
                  color={"#FFB900"}
                >
                  Interested in ONDC?
                </Typography>

                <Typography
                  fontSize={"12px"}
                  lineHeight={"16.34px"}
                  fontWeight={"400"}
                  fontFamily={"open sans"}
                  color={"#4A4A4A"}
                >
                  Share following details and book a free demo.
                </Typography>
              </Box>

              <Box className={classes.closeIcon}>
                <Box
                  onClick={handleClose}
                  component="img"
                  src={UI_KIT.ICONS.close}
                  justifyContent={"flex-start"}
                  alignItems="flex-start"
                  display={"flex"}
                  alignSelf={"center"}
                />
              </Box>
            </Stack>
            <Stack
              flex={1}
              className={classes.textfieldsContainer}
              direction={{ xs: "column", sm: "row" }}
            >
              <FormControl className={classes.formControl}>
                <div className="open-sans"></div>
                <CustomInput
                  label={<span className="open-sans">Your full name*</span>}
                  onChange={(e) =>
                    setFullName({
                      ...fullName,
                      isValid: true,
                      dirty: false,
                      value: e.target.value,
                    })
                  }
                  value={fullName.value}
                  key={1}
                  isDirty={fullName.dirty}
                  isValid={fullName.isValid}
                  field={"Full Name"}
                  onBlur={() => {
                    if (fullName.value) {
                      setFullName({ ...fullName, isValid: true, dirty: true });
                    } else {
                      setFullName({
                        ...fullName,
                        isValid: false,
                        dirty: false,
                      });
                    }
                  }}
                  onFocus={() => {}}
                />

                <CustomInput
                  label={<span className="open-sans">Your contact number*</span>}
                  onChange={(e) =>
                    setPhone({
                      ...phone,
                      isValid: true,
                      dirty: false,
                      value: e.target.value,
                    })
                  }
                  value={phone.value}
                  key={2}
                  inputProps={{
                    maxLength: 10,
                  }}
                  isDirty={phone.dirty}
                  isValid={phone.isValid}
                  field={"Full Name"}
                  onBlur={() => {
                    if (phone.value) {
                      setPhone({ ...phone, isValid: true, dirty: true });
                    } else {
                      setPhone({
                        ...phone,
                        isValid: false,
                        dirty: false,
                      });
                    }
                  }}
                  onFocus={() => {}}
                />

                <CustomInput
                  label={<span className="open-sans">Your email ID*</span>}
                  onChange={(e) =>
                    setEmail({
                      ...email,
                      isValid: true,
                      dirty: false,
                      value: e.target.value,
                    })
                  }
                  value={email.value}
                  key={3}
                  isDirty={email.dirty}
                  isValid={email.isValid}
                  field={"Email"}
                  onBlur={() => {
                    if (email.value) {
                      setEmail({ ...email, isValid: true, dirty: true });
                    } else {
                      setEmail({
                        ...email,
                        isValid: false,
                        dirty: false,
                      });
                    }
                  }}
                  onFocus={() => {}}
                />

                <CustomInput
                  label={<span className="open-sans">Your business name*</span>}
                  onChange={(e) =>
                    setBusinessName({
                      ...businessName,
                      isValid: true,
                      dirty: false,
                      value: e.target.value,
                    })
                  }
                  value={businessName.value}
                  key={4}
                  isDirty={businessName.dirty}
                  isValid={businessName.isValid}
                  field={"Business name"}
                  onBlur={() => {
                    if (businessName.value) {
                      setBusinessName({
                        ...businessName,
                        isValid: true,
                        dirty: true,
                      });
                    } else {
                      setBusinessName({
                        ...businessName,
                        isValid: false,
                        dirty: false,
                      });
                    }
                  }}
                  onFocus={() => {}}
                />

                <CustomDropdown
                  data={BUSINESS_CATEGORIES}
                  label={<span className="open-sans">Business category</span>}
                  selectionChangeHandler={selectionChangeHandler}
                  selected={category.value}
                  isDirty={category.dirty}
                  isValid={category.isValid}
                  onBlur={() => {}}
                />

                {customCategory.isActive && (
                  <CustomInput
                    label={"Please specify*"}
                    onChange={(e) =>
                      setCustomCategory({
                        ...customCategory,
                        isValid: true,
                        dirty: false,
                        value: e.target.value,
                      })
                    }
                    value={customCategory.value}
                    key={5}
                    isDirty={customCategory.dirty}
                    isValid={customCategory.isValid}
                    field={"Business name"}
                    onBlur={() => {
                      if (customCategory.value) {
                        setCustomCategory({
                          ...customCategory,
                          isValid: true,
                          dirty: true,
                        });
                      } else {
                        setCustomCategory({
                          ...customCategory,
                          isValid: false,
                          dirty: false,
                        });
                      }
                    }}
                    onFocus={() => {}}
                  />
                )}

                <CustomDropdown
                  data={BUSINESS_TYPE}
                  label={<span className="open-sans">Business type</span>}
                  selectionChangeHandler={selectionChangeHandlerforBusinessType}
                  selected={businessType.value}
                  isDirty={businessType.dirty}
                  isValid={businessType.isValid}
                  onBlur={() => {}}
                />

                {customBusinessType.isActive && (
                  <CustomInput
                    label={"Please specify*"}
                    onChange={(e) =>
                      setCustomBusinessType({
                        ...customBusinessType,
                        isValid: true,
                        dirty: false,
                        value: e.target.value,
                      })
                    }
                    value={customBusinessType.value}
                    key={6}
                    isDirty={customBusinessType.dirty}
                    isValid={customBusinessType.isValid}
                    field={"Business name"}
                    onBlur={() => {
                      if (customBusinessType.value) {
                        setCustomBusinessType({
                          ...customBusinessType,
                          isValid: true,
                          dirty: true,
                        });
                      } else {
                        setCustomBusinessType({
                          ...customBusinessType,
                          isValid: false,
                          dirty: false,
                        });
                      }
                    }}
                    onFocus={() => {}}
                  />
                )}

                {/* intent */}
                <CustomInput
                  label={<span className="open-sans">Please state your interest in ONDC</span>}
                  onChange={(e) =>
                    setBusinessInterest({
                      value: e.target.value,
                    })
                  }
                  value={businessInterest.value}
                  multiline={true}
                  key={7}
                  isDirty={false}
                  isValid={false}
                  field={"Business interest"}
                  onBlur={() => {
                    if (businessInterest.value) {
                      setBusinessInterest({
                        ...businessInterest,
                      });
                    } else {
                      setBusinessInterest({
                        ...businessInterest,
                      });
                    }
                  }}
                  onFocus={() => {}}
                />

                <CustomButton2
                  onClick={() => handleSubmit()}
                  title="Submit details"
                  containerStyle={classes.customContainerBtn}
                />
                <Typography
                  fontSize={{
                    xs: "12px",
                    sm: "12px",
                    md: "12px",
                    xl: "12px",
                    lg: "12px",
                  }}
                  fontFamily={"open sans"}
                  textAlign={"center"}
                  margin
                  sx={{
                    padding: "35px 37px 0 37px !important",
                    margin: "auto !important",
                  }}
                >
                  By clicking ‘Submit’, I agree to allow NowFloats to contact
                  me.
                </Typography>
              </FormControl>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
}
