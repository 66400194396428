import React, { Component } from "react";
import { Route, Navigate, Routes, BrowserRouter } from "react-router-dom";
import CautionNotice from "../pages/caution-notice/cautionNotice";
import Home from "../pages/home";
import { ROUTES } from "./routes";

function AppNavigation() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path={ROUTES.HOME} element={<Home />} />
        <Route exact path={ROUTES.CAUTION_NOTICE} element={<CautionNotice />} />
        <Route
          exact
          path={"*"}
          element={<Navigate to="/" replace={true} />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default AppNavigation;
