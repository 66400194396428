import * as React from "react";
import Stack from "@mui/material/Stack";
import { Button, createStyles } from "@mui/material";
import { makeStyles } from "@mui/styles";
import theme from "../configs/theme";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: "#FFB900 !important",
      borderRadius: "50px !important",
      // padding: "14px 16px 8px 16px !important",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
      textTransform: "none !important",
      // margin: "1rem 0 1rem 0",
      "&:hover": {
        backgroundColor: " #FFB900",
      },
      [theme.breakpoints.up("xs")]: {
        padding: "0 0 30px 0",
        height: "39px",
        width: "296px",
      },
      [theme.breakpoints.up("md")]: {
        padding: "0 0 30px 0",
        height: "39px",
        width: "180px",
      },
    },
  })
);

export function CustomButton2({ onClick, title, containerStyle }) {
  const classes = useStyles(theme);

  return (
    <Button
      onClick={onClick}
      disableElevation={true}
      variant="contained"
      sx={{
        height: { sm: "40px" },
        width: { sm: "180px" },
      }}
      // disableRipple
      className={[classes.root, containerStyle || {}]}
    >
      <span
        style={{
          // marginTop: "4px",
          fontSize: "16px",
          fontWeight: "700",
          lineHeight: "21.79px",
        }}
      >
        {title || ""}
      </span>
    </Button>
  );
}
