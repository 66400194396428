import React, { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { UI_KIT } from "../../assets";
import { HoverEffectButton } from "../../components/HoverEffectButton";
import theme from "../../configs/theme";
import { createStyles, makeStyles } from "@mui/styles";
import ConnectForm from "../../components/connectForm";
import { useEffect } from "react";
import { handleWindowResize } from "../../components/utils";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flex: 1,
      backgroundColor: "white",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.up("xs")]: {
        padding: "40px 0",
      },
      [theme.breakpoints.up("sm")]: {
        margin: "0px 20px",
        padding: "0px !important",
      },
      [theme.breakpoints.up("md")]: {
        padding: "",
        margin: "auto",
      },
      [theme.breakpoints.up("lg")]: {
        padding: "",
        margin: "auto",
      },
      [theme.breakpoints.up("xl")]: {
        padding: "",
        margin: "auto",
      },
      maxWidth: "1300px !important",
    },

    customZadingaBtn: {
      alignSelf: "center",
      [theme.breakpoints.up("xs")]: {
        width: "240px",
      },
      [theme.breakpoints.up("sm")]: {
        width: "500px !important",
        height: "60px",
      },
      [theme.breakpoints.up("md")]: {
        width: "1099px !important",
        height: "80px",
      },
      [theme.breakpoints.up("lg")]: {
        width: "1099px !important",
        height: "80px",
      },
      [theme.breakpoints.up("xl")]: {
        width: "1099px !important",
        height: "80px",
      },
    },

    container: {
      border: "1px solid",
      borderColor: "#CBCBCB",
      borderRadius: 20,
      padding: "0rem 0 3rem 0rem",
      margin: "0 0 5rem 0",
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    kitsune: {},
    zadinga: {
      margin: "auto",
      padding: "2rem 0 0 0",
      zIndex: 10,
    },
    BtnContainer: {
      width: { sm: "500px !important" },
    },
    ONDCtitle: {
      textAlign: "center !important",
      fontWeight: "700 !important",

      [theme.breakpoints.up("xs")]: {
        fontSize: "24px !important",
        lineHeight: "26.52px !important",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "24px !important",
        lineHeight: "44.2px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "40px !important",
        lineHeight: "44.2px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "40px !important",
        lineHeight: "44.2px",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "40px !important",
        lineHeight: "44.2px",
      },
    },
  })
);

export default function BusinessTypes() {
  const [open, setOpen] = React.useState(false);
  const [businessType, setBusinessType] = React.useState("");
  const [kitsuneposY, setkitsuneposY] = useState(0);
  const [zadingaposY, setzadingaposY] = useState(0);

  const classes = useStyles(theme);

  useEffect(() => {
    setkitsuneposY(window.innerHeight + 0.785 * window.innerHeight);
    setzadingaposY(window.innerHeight + 1.31 * window.innerHeight);
  }, []);

  const handleClose = () => setOpen(false);
  const handleOpen = (type) => {
    setOpen(true);
    setBusinessType(type);
  };

  return (
    <Box className={classes.root}>
      <ConnectForm
        handleClose={handleClose}
        open={open}
        businessTypeProp={businessType}
      />
      <Stack>
        {/* title */}
        <Stack
          flexDirection={{ xs: "column", sm: "row" }}
          alignItems={"center"}
          justifyContent={"center"}
          padding={{ xs: "0px 60px 70px 60px", sm: "100px 0 115px 0" }}
        >
          <Stack
            flexDirection={{ xs: "row", sm: "row", md: "row" }}
            justifyContent="center"
            alignItems={"center"}
          >
            <Typography className={classes.ONDCtitle}>
              Explore our&nbsp;
            </Typography>
            <Box
              component="img"
              alt="ondc_logo"
              src={UI_KIT.ondc_logo_small}
              alignSelf="center"
              height={{
                xs: "28px",
                sm: "32px",
                md: "50px",
                lg: "50px",
                xl: "50px",
              }}
            />
          </Stack>
          <Typography className={classes.ONDCtitle}>
            &nbsp;enabled solutions
          </Typography>
        </Stack>

        {/* for desktop n mobile */}
        {!handleWindowResize().isTablet && (
          <Stack flex={1} display={"flex"}>
            <Box
              component="img"
              alt="ondc_logo"
              src={UI_KIT.kitsune_logo}
              alignSelf="center"
              width={{
                xs: "147px",
                sm: "243.34px",
                md: "243.34px",
                lg: "243.34px",
                xl: "243.34px",
              }}
              margin={{ sm: "0 20px", xs: "0" }}
              bgcolor={"white"}
              className={classes.kitsune}
              sx={{
                position: "absolute",
                zIndex: 10,
                padding: { xs: "0 10px", sm: "0 10px" },
                marginTop: { sm: "-65px !important", xs: "-35px !important" },
              }}
            />

            <Stack
              className={classes.container}
              margin={{
                xs: "0 20px !important",
                sm: "0px !important",
                md: "10px",
              }}
            >
              <Stack
                paddingTop={{
                  xs: "51px",
                  md: "110px",
                  sm: "110px",
                  md: "110px",
                  lg: "110px",
                }}
              >
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  display={"flex"}
                  width={"100%"}
                  flex={1}
                  justifyContent={"space-between"}
                  paddingBottom={{ xs: "0", sm: "2rem" }}
                >
                  <Box padding={{ xs: "0.5rem 1rem", sm: "0rem", md:"0" }} key={1}>
                    <HoverEffectButton
                      onClick={() =>
                        handleOpen("Omni Channel Retail Businesses")
                      }
                      title={`Omni Channel Retail Businesses`}
                    />
                  </Box>
                  <Box padding={{ xs: "0.5rem 1rem", sm: "0 1rem" }} key={2}>
                    <HoverEffectButton
                      onClick={() => handleOpen("Enterprise Online Commerce")}
                      title={`Enterprise Online Commerce`}
                    />
                  </Box>
                  <Box padding={{ xs: "0.5rem 1rem", sm: "0 1rem" }} key={3}>
                    <HoverEffectButton
                      url={"https://www.getboost360.com/d2c/"}
                      title={`D2C Business`}
                    />
                  </Box>
                </Stack>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  alignSelf={"center"}
                >
                  <Box key={4} padding={{ xs: "0.5rem 1rem", sm: "0 1rem" }}>
                    <HoverEffectButton
                      onClick={() =>
                        handleOpen("Regional brand (single & multiple stores)")
                      }
                      title={`Regional Brands`}
                      subtitle={`(Single & Multiple Stores)`}
                    />
                  </Box>
                  <Box key={5} padding={{ xs: "0.5rem 1rem", sm: "0 1rem" }}>
                    <HoverEffectButton
                      onClick={() => handleOpen("Seller Aggregators")}
                      title={`Seller Aggregators`}
                    />
                  </Box>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        )}

        {/* for tab */}
        {handleWindowResize().isTablet && (
          <Stack flex={1} display={"flex"}>
            <Box
              component="img"
              alt="ondc_logo"
              src={UI_KIT.kitsune_logo}
              alignSelf="center"
              width={{
                xs: "147px",
                sm: "243.34px",
                md: "243.34px",
                lg: "243.34px",
                xl: "243.34px",
              }}
              margin={{ sm: "0 20px", xs: "0" }}
              bgcolor={"white"}
              className={classes.kitsune}
              sx={{
                position: "absolute",
                zIndex: 10,
                padding: { xs: "0 10px", sm: "0 10px" },
                marginTop: { sm: "-65px !important", xs: "-35px !important" },
              }}
            />

            <Stack
              className={classes.container}
              margin={{ xs: "0 20px !important", sm: "0px !important" }}
            >
              <Stack
                paddingTop={{
                  xs: "51px",
                  md: "110px",
                  sm: "110px",
                  md: "110px",
                  lg: "110px",
                }}
              >
                {/* 2 */}
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  display={"flex"}
                  width={"100%"}
                  flex={1}
                  justifyContent={"space-between"}
                  paddingBottom={{ xs: "0", sm: "2rem" }}
                >
                  <Box padding={{ xs: "0.5rem 1rem", sm: "0 1rem" }} key={1}>
                    <HoverEffectButton
                      onClick={() =>
                        handleOpen("Omni Channel Retail Businesses")
                      }
                      title={`Omni Channel Retail Businesses`}
                    />
                  </Box>
                  <Box padding={{ xs: "0.5rem 1rem", sm: "0 1rem" }} key={2}>
                    <HoverEffectButton
                      onClick={() => handleOpen("Enterprise Online Commerce")}
                      title={`Enterprise Online Commerce`}
                    />
                  </Box>
                </Stack>
                {/* 2 */}
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  alignSelf={"center"}
                >
                  <Box padding={{ xs: "0.5rem 1rem", sm: "0 1rem" }} key={3}>
                    <HoverEffectButton
                      url={"https://www.getboost360.com/d2c/"}
                      title={`D2C Business`}
                    />
                  </Box>
                  <Box key={4} padding={{ xs: "0.5rem 1rem", sm: "0 1rem" }}>
                    <HoverEffectButton
                      onClick={() =>
                        handleOpen("Regional brand (single & multiple stores)")
                      }
                      title={`Regional Brands`}
                      subtitle={`(Single & Multiple Stores)`}
                    />
                  </Box>
                </Stack>
                {/* 1 */}
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  alignSelf={"center"}
                >
                  <Box key={5} padding={{ sm: "30px 1rem" }}>
                    <HoverEffectButton
                      onClick={() => handleOpen("Seller Aggregators")}
                      title={`Seller Aggregators`}
                    />
                  </Box>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>

      {/* zadinga logo */}
      <Stack
        className={classes.container}
        margin={{
          xs: "50px 20px 0 20px !important",
          sm: "112px 0 100px !important",
          md: "112px 0 100px !important",
        }}
        paddingTop="52px"
        sx={{
          height: {
            xs: "145px",
            sm: "182px",
            md: "182px",
            lg: "182px",
            xl: "182px",
          },
          display: "flex !important",
          flex: 1,
          justifyContent: "center !important",
          alignItems: "center !important",
        }}
      >
        <Box
          component="img"
          alt="ondc_logo"
          src={UI_KIT.zadinga_logo}
          alignSelf="center"
          width={{
            xs: "157px",
            sm: "266px",
            md: "266px",
            lg: "266px",
            xl: "266px",
          }}
          height={{
            xs: "43px",
            sm: "73px",
            md: "73px",
            lg: "73px",
            xl: "73px",
          }}
          margin={{ sm: "0 10px", xs: "0" }}
          bgcolor={"white"}
          className={classes.kitsune}
          sx={{
            position: "absolute",
            zIndex: 10,
            padding: "0 10px",
            marginTop: {
              xs: "-72px !important",
              sm: "-97px !important",
              md: "-95px !important",
              // lg: "-185px !important",
            },
          }}
        />

        <HoverEffectButton
          onClick={() => handleOpen("SMB & MSME Retail Business")}
          title={`SMB & MSME Retail Business`}
          subtitle={
            handleWindowResize().isMobile || handleWindowResize().isTablet
              ? ``
              : `(Any Small Enterprise Business or Any Local Shop Owner)`
          }
          containerStyle={classes.customZadingaBtn}
        />
      </Stack>
    </Box>
  );
}
