import logo from "./logo.svg";
import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import { Container, CssBaseline } from "@mui/material";
import AppNavigation from "./routes/AppNavigation";
import theme from "./configs/theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppNavigation />
    </ThemeProvider>
  );
}

export default App;
