import { createStyles, makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ConnectForm from "../../components/connectForm";
import { Footer } from "../../components/footer";
import Header from "../../components/header";
import theme from "../../configs/theme";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up("xs")]: {
        padding: "120px 0px 0px 0px",
      },
      [theme.breakpoints.up("sm")]: {
        padding: "100px 0px 0px 0px",
        margin: "0 !important",
      },
      [theme.breakpoints.up("md")]: {
        // padding: "100px",
      },
      [theme.breakpoints.up("lg")]: {
        // padding: "100px",
      },
      [theme.breakpoints.up("xl")]: {
        // padding: "100px",
      },
    },
  })
);

export default function CautionNotice() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const classes = useStyles(theme);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  return (
    <Box
      className={classes.root}
      sx={{
        overflow: "hidden !important",
      }}
    >
      <Header />
      <ConnectForm handleClose={handleClose} open={open} />
      <Box maxWidth="1300px !important" margin="auto">
        <main style={{ display: "flex" }}>
          <div className="container">
            <div className="main-content">
              <p>
                <strong>
                  <u>CAUTION NOTICE – BEWARE OF FRAUD</u>
                </strong>
              </p>
              <br />
              <p>
                It has come to the notice of &nbsp;
                <Link to="/">https://ondc.nowfloats.com/</Link>&nbsp; (Portal)
                that, some fraudsters are approaching customers (a) with
                promotional offers from the Portal; (b) through fake email IDs
                as well as fake domain names/website links which may appear
                confusingly similar or identical with the Portal and its Group
                Companies; (c) through Social media Apps such as
                WhatsApp/Facebook/Instagram/Twitter by including the
                trademark/logo and/or the logo of the Portal as part of their
                profile picture and pushing fake offers to the innocent public,
                by luring them with false promises; and (d) making physical
                visits at customers door step seeking OTP to process
                cancellation of order that was not placed by you.
              </p>
              <br />
              <p>
                CUSTOMERS, GENERAL PUBLIC AND PROSPECTIVE CUSTOMERS ARE HEREBY
                CAUTIONED:
              </p>
              <br />

              <p>
                (i) To exercise caution against fraudsters who may use fake
                email IDs, fake profiles on Social media (WhatsApp, Facebook,
                Instagram, Twitter, etc.,) domains, websites, telephones and
                advertisements in newspapers/magazines, etc. by impersonating
                themselves to be from the Portal and/or its Group Companies and
                claim to offer promotional offers, collect customer account
                details, ask for advance money to process winners, etc.;
              </p>
              <br />

              <p>
                (ii) Portal or its Group Companies or any of its representatives
                will NOT call for any advance payment of money from any of its
                prospects/customers;
              </p>
              <br />

              <p>
                (iii) Portal or any of its representatives would NOT ask for any
                OTP or PIN to offer any promotional offers; (iv) Portal or any
                of its representatives would NOT ask for any OTP or PIN for
                cancellation of orders at your door step. You are obliged NOT to
                share OTP/PIN with any person or authorizing UPI requests in
                wallets, received from unknown sources, as the same may lead to
                a fraud, through which, money may be debited from your Bank
                account and credited to fraudster’s account
              </p>
              <br />

              <p>
                (iv) Portal email ID contains grievance@nowfloats.com ALONE and
                does NOT contain any other domain name such as
                Gmail/Yahoo/Rediff/Ymail, etc., or in any other form;
              </p>
              <br />

              <p>
                (v) To exercise caution against fraudsters luring you with
                fictitious offers by calling you over unidentified phone
                numbers, fake email ids, fake website/domain and/or through any
                other physical or electronic medium and styling themselves to be
                employee/representative of Portal and/or its Group Companies.
              </p>
              <p>
                Before dealing with such fraudsters and/or responding/accessing
                any fraudulent advertisements, telephone calls, emails and
                website, to protect yourself against any fraud and criminal acts
                of the perpetrators, you are advised to act responsibly and are
                solely obliged to verify: (a) by reaching out to Official
                customer care number of Portal; or (b) the veracity of such
                claims from the Company’s official website&nbsp;
                <a href="https://www.nowfloats.com/" target="_blank">
                  https://www.nowfloats.com
                </a>
                .
              </p>

              <br />
              <br />
              <p>
                General Public and prospective customers are also advised to
                immediately report any suspicious incident and/or incident of
                defrauding of money as a result of these fraudulent acts and
                practices to the authorities in their jurisdiction, i.e. the
                Police and the Telecommunications regulator, including the Cyber
                Crime Cell. Please note that any person dealing with such
                fraudsters will be dealing at his/her own risk and
                responsibility.
                <strong>NowFloats Technologies Limited</strong> and/or any of
                its Group Companies will not be responsible for any loss
                suffered or otherwise in this respect.
              </p>

              <br />
            </div>
          </div>
        </main>
      </Box>
      <Footer />
    </Box>
  );
}
