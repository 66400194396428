import * as React from "react";
import { Box, Button, createStyles, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import theme from "../configs/theme";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: "white !important",
      borderColor: "#FFB900 !important",
      color: "#FFB900 !important",
      borderRadius: "60px !important",
      boxShadow: "0px 0px 6.93852px rgba(0, 0, 0, 0.1) !important",
      [theme.breakpoints.up("xs")]: {
        width: "240px",
        minHeight: "54px !important",
      },
      [theme.breakpoints.up("sm")]: {
        width: "250px",
        minHeight: "60px !important",
        border: "2px solid",
      },
      [theme.breakpoints.up("md")]: {
        width: "340px",
        height: "80px",
      },
      [theme.breakpoints.up("xl")]: {
        width: "340px",
        height: "80px",
      },
      [theme.breakpoints.up("lg")]: {
        width: "340px",
        height: "80px",
      },
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
      textTransform: "none !important",
    },
    subtitle: {
      fontSize: "12px !important",
      color: "#8E8E8E",
      fontFamily: "open sans !important",
    },
    title: {
      fontWeight: "700 !important",
      fontSize: { sm: "18px !important", xs: "14px" },
      fontFamily: "open sans !important",

      [theme.breakpoints.up("xs")]: {
        fontSize: "14px !important",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "16px !important",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "20px !important",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "20px !important",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "20px !important",
      },
    },
    hover: {
      "&:hover": {
        backgroundColor: "#FFB900 !important",
        color: "white !important",
      },
    },
  })
);

export function HoverEffectButton({
  onClick,
  title,
  containerStyle,
  subtitle,
  url,
}) {
  const classes = useStyles(theme);

  return url ? (
    <Button
      target="_blank"
      component="a"
      href={url}
      disableElevation={true}
      onClick={() => {}}
      sx={{
        border: "1px solid #FFB900",
      }}
      variant="contained"
      className={[classes.root, containerStyle, classes.hover || {}]}
    >
      <Box>
        <Typography className={classes.title}>{title || ""}</Typography>
        <Typography className={[classes.subtitle, classes.hover]}>
          {subtitle || ""}
        </Typography>
      </Box>
    </Button>
  ) : (
    <Button
      disableElevation={true}
      onClick={onClick}
      sx={{
        border: "1px solid #FFB900",
      }}
      variant="contained"
      className={[classes.root, containerStyle, classes.hover || {}]}
    >
      <Box>
        <Typography className={classes.title}>{title || ""}</Typography>
        <Typography className={[classes.subtitle]}>{subtitle || ""}</Typography>
      </Box>
    </Button>
  );
}
