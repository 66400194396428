import { AppBar, Box, Container, createStyles, Stack } from "@mui/material";
import React from "react";
import { UI_KIT } from "../assets";
import { makeStyles } from "@mui/styles";
import { CustomButton2 } from "./CustomButton2";
import ConnectForm from "./connectForm";
import { handleWindowResize } from "./utils";
import "./header.css";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      minHeight: "10px",
    },
    mobileHeader: {
      boxShadow: "0px 1px 1px #de1dde",
    },
  })
);

export default function Header() {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className={classes.root}>
      <ConnectForm handleClose={handleClose} open={open} />
      <AppBar
        position="fixed"
        style={{ backgroundColor: "white" }}
        sx={{
          width: { xs: "110%", sm: "100%" },
          height: "100px !important",
          borderBottom: "1px solid #E0E0E0",
          backgroundColor: "white",
          margin: "auto !important",
        }}
        elevation={handleWindowResize().isMobile ? 1 : 0}
      >
        <Container
          sx={{
            maxWidth: "1350px !important",
            flex: 1,
            display: "flex !important",
          }}
        >
          <Box flex={1} display={"flex"} justifyContent={"space-between"}>
            <Stack
              style={{
                alignItems: "center",
              }}
              direction={"row"}
              justifyContent={{
                xs: "center",
                sm: "center",
                md: "center",
                lg: "space-between",
                xl: "space-between",
              }}
              sx={{
                width: { xs: "132%", sm: "132%", md: "100%", lg: "100%" },
              }}
            >
              <Link to={'/'}>
                <Box
                  component="img"
                  alt="Logo"
                  src={UI_KIT.new_logo}
                  justifyContent={"center"}
                  alignItems="center"
                  display={"flex"}
                  sx={{
                    width: {
                      xs: "30%",
                      sm: "250.84px",
                      md: "80.84px",
                      xl: "80.00px",
                    },
                    // margin: "auto",
                    paddingLeft: { xs: "30px", sm: "0px" },
                  }}
                />
              </Link>

              {handleWindowResize().isDesktop && (
                <Box
                  id="btn"
                  justifyContent={"center"}
                  alignItems="center"
                  display={"flex"}
                  sx={{
                    marginBottom: { xs: "10px", sm: "0px" },
                    marginRight: { xs: "40px", sm: "0px" },
                  }}
                >
                  <CustomButton2 onClick={handleOpen} title="Get a free demo" />
                </Box>
              )}
            </Stack>
          </Box>
        </Container>
      </AppBar>
    </div>
  );
}
