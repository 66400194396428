import { MenuItem, Select, TextField } from "@mui/material";
import React from "react";

export default function CustomDropdown({
  selectionChangeHandler,
  data,
  selected,
  isDirty,
  isValid,
  onBlur,
  label,
}) {
  return (
    <TextField
      id="filled-select-currency"
      select
      label={label || "Please select"}
      error={isDirty && isValid === false}
      onBlur={onBlur}
      value={selected}
      onChange={selectionChangeHandler}
      placeholder={"Select Category"}
      variant="standard"
      style={{
        margin: "0 0 1rem 0",
        // width: {
        //   xs: "275px !important",
        //   sm: "275px !important",
        //   lg: "275px !important",
        //   xl: "275px !important",
        // },
        // height: { xs: "30px", sm: "30px" },
        // margin: "auto",
      }}
    >
      {data.map((el) => {
        return (
          <MenuItem value={el.id} key={el.id}>
            {el.title}
          </MenuItem>
        );
      })}
    </TextField>
  );
}
