import { Box, Container, Stack, Typography } from "@mui/material";
import React from "react";
import { CATEGORIES } from "../../components/constants";

export default function BusinessCategories() {
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex !important",
        width: "100% !important",
        backgroundColor: "#F8F8F8 !important",
        justifyContent: "center",
        alignItems: "center",
        padding: { xs: "30px 0", sm: "60px 0", lg: "100px 0" },
        // {
        //   xs: "100px 0 !important",
        //   sm: "100px 0 !important",
        //   md: "100px 0 !important",
        //   lg: "100px 0 !important",
        //   xl: "100px 0 !important",
        // },
      }}
    >
      <Box
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          maxWidth: "1300px",
          margin: "auto",
        }}
      >
        <Typography
          textAlign={"center"}
          sx={{
            fontSize: { xs: "24px", sm: "24px", md: "40px" },
            lineHeight: { xs: "26px", sm: "44.2px", md: "44.2px" },
            fontWeight: "700",
            // textAlign: { xs: "center" },
            padding: {
              xs: "20px 0 40px 0",
              sm: "0",
              md: "0",
              lg: "0",
              xl: "0",
            },
          }}
        >
          Business Categories
        </Typography>
        <Stack
          padding={{
            sm: "30px 30px 30px 30px",
            md: "50px 0rem 0rem 0rem",
            lg: "50px 0rem 0rem 0rem",
            xl: "50px 0rem 0rem 0rem",
          }}
        >
          <Stack
            spacing={{ xs: 1, sm: 2, md: 4 }}
            direction={"row"}
            justifyContent={"space-between"}
            display={"flex"}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            {CATEGORIES.map((el, index) => {
              return (
                <Stack
                  display={"flex"}
                  key={index}
                  justifyContent={"center !important"}
                  alignItems={{ xs: "none", sm: "center" }}
                  padding={{ xs: "0 25px 4px 40px", sm: "10px" }}
                >
                  <Box
                    component="img"
                    alt="Logo"
                    src={require("../../assets/icons/" + el.src + ".svg")}
                    justifyContent={"center"}
                    alignItems="center"
                    display={"flex"}
                    width={{ xs: "50px", sm: "60px", md: "70px", xl: "90px" }}
                    height={{ xs: "50px", sm: "60px", md: "70px", xl: "90px" }}
                  />
                  <Typography
                    textAlign={{ xs: "center", sm: "center" }}
                    justifyContent={"normal"}
                    fontWeight="600"
                    fontSize={{ xs: "14px", sm: "14px", md: "18px" }}
                    paddingTop={{ xs: "0", sm: "2rem" }}
                    paddingBottom={{ xs: "10px", sm: "0px" }}
                    position={{ xs: "absolute", sm: "relative" }}
                    left={{ xs: "120px", sm: "0px" }}
                    // top={{ xs: "0px", sm: "0" }}
                    fontFamily={"open sans"}
                  >
                    {el.title}
                  </Typography>
                  &nbsp;
                </Stack>
              );
            })}
          </Stack>
          <Box padding={{ xs: "10px 0 20px 0", sm: "30px 0 0 0" }}>
            <Typography
              fontFamily={"open sans"}
              color={"#ADADAD"}
              textAlign={"center"}
              fontSize={{ xs: "14px", sm: "18px" }}
              lineHeight={"25.2px"}
              fontWeight="400"
            >
              More categories coming soon...
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}
