import * as React from "react";
import Header from "../../components/header";
import { Box, createStyles, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import theme from "../../configs/theme";
import { UI_KIT } from "../../assets";
import BusinessCategories from "./businessCategories";
import BusinessTypes from "./businessTypes";
import ONDC from "./ONDC";
import HowONDCWorks from "./HowONDCWorks";
import WhyChoose from "./WhyChoose";
import { Footer } from "../../components/footer";
import ConnectForm from "../../components/connectForm";
import { CustomButton2 } from "../../components/CustomButton2";
import "./home.css";
import { useState } from "react";
import { handleWindowResize } from "../../components/utils";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up("xs")]: {
        padding: "120px 0px 0px 0px",
      },
      [theme.breakpoints.up("sm")]: {
        // padding: "100px 0px 0px 0px",
        margin: "0 !important",
      },
      [theme.breakpoints.up("md")]: {
        // padding: "100px",
      },
      [theme.breakpoints.up("lg")]: {
        // padding: "100px",
      },
      [theme.breakpoints.up("xl")]: {
        // padding: "100px",
      },
    },
  })
);
export default function Home() {
  const ONDC_CONNECT = UI_KIT.new_web;
  const ONDC_CONNECT_MOBILE = UI_KIT.new_mobile;
  const [open, setOpen] = React.useState(false);
  const classes = useStyles(theme);
  const [[x, y], setXY] = useState([0, 0]);
  const [[glassPosX, glassPosY], setGlassPos] = useState([0, 0]);
  const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
  const [showMagnifier, setShowMagnifier] = useState(false);
  const magnifierHeight = 100;
  const magnifieWidth = 100;
  const zoomLevel = 3;

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  React.useEffect(() => {
    const queryParameters = window.location.href;
    if (queryParameters.includes("#form")) {
      handleOpen();
    }
  }, []);

  return (
    <Box
      className={classes.root}
      sx={{
        overflow: "hidden !important",
      }}
    >
      <Header />
      <ConnectForm handleClose={handleClose} open={open} />
      <Box maxWidth="1300px !important" margin="auto">
        <Box
          sx={{
            flex: 1,
            flexDirection: {
              xs: "column-reverse",
              sm: "column-reverse",
              md: "row",
              xl: "row",
              lg: "row",
            },
            padding: {
              sm: "30px 0 !important",
              xl: "0px 0 !important",
              lg: "100px 0 !important",
              md: "100px 0 !important",
            },
            display: "flex !important",
            justifyContent: "space-between",
          }}
        >
          <Box
            alignItems={"center"}
            alignSelf={{ xs: "flex-start", sm: "center", md: "flex-start" }}
            padding={{
              xs: "30px 20px !important",
              sm: "0px 40px 0 0",
              md: "30px 40px 0 0 !important",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "40px", sm: "40px", lg: "50px", xl: "50px" },
                marginBottom: {
                  xs: "16px",
                  sm: "30px",
                  md: "30px",
                  xl: "30px",
                  lg: "30px",
                },
                marginTop: { xs: "0px", sm: "50px", lg: "0px" },
              }}
              lineHeight={{ sm: "60px", xs: "48px" }}
              fontWeight={"700"}
              textAlign={{ xs: "center", sm: "center", md: "left" }}
              color="#4A4A4A"
            >
              Connect your business to ONDC
            </Typography>
            <Typography
              fontFamily={"open sans"}
              textAlign={{ xs: "center", sm: "center", md: "left" }}
              sx={{
                lineHeight: { xs: "25.6px", sm: "32px" },
                paddingBottom: { xs: "20px", sm: "20px" },
                fontSize: { xs: "16px", sm: "16px", md: "20px" },
                fontWeight: "400",
                color: "#747474",
                padding: { xs: "20px", sm: "0px" },
              }}
            >
              If you are on a platform like Shopify, WooCommerce, Unicommerce,
              Wix etc., NowFloats supports integrations that will enable your
              business to become ONDC-ready.
            </Typography>

            <Typography
              fontFamily={"open sans"}
              textAlign={{ xs: "center", sm: "center", md: "left" }}
              sx={{
                marginTop: {
                  xs: "16px",
                  sm: "30px",
                  md: "30px",
                  xl: "30px",
                  lg: "30px",
                },
                lineHeight: { xs: "25.6px", sm: "32px" },
                paddingBottom: { xs: "20px", sm: "20px" },
                fontSize: { xs: "16px", sm: "16px", md: "20px" },
                fontWeight: "400",
                color: "#747474",
                padding: { xs: "20px", sm: "0px" },
              }}
            >
              Join the world’s largest open network ONDC with a simple API
              plug-in and UI for the fastest e-commerce experience.
            </Typography>
            {handleWindowResize().isDesktop && (
              <Box sx={{ margin: { xs: "", sm: "20px 0 " } }}>
                <CustomButton2 onClick={handleOpen} title="Get a free demo" />
              </Box>
            )}
          </Box>

          <Box>
            <div
              style={{
                position: "relative",
                width: {
                  xs: "355px",
                  sm: "90%",
                  md: "650px",
                  xl: "650px",
                  lg: "650px",
                },
              }}
            >
              <Box
                component="img"
                onMouseEnter={(e) => {
                  const elem = e.currentTarget;
                  const { width, height } = elem.getBoundingClientRect();
                  setSize([width, height]);
                  setShowMagnifier(true);
                }}
                onMouseMove={(e) => {
                  const elem = e.currentTarget;
                  const { top, left } = elem.getBoundingClientRect();

                  // calculate cursor position on the image
                  const x = e.pageX - left - window.scrollX;
                  const y = e.pageY - top - window.scrollY;
                  // const posX = e.clientX;
                  // const posY = e.clientY;
                  const posX = e.pageX - 60;
                  const posY = e.pageY - 60;
                  setXY([x, y]);
                  setGlassPos([posX, posY]);
                }}
                onMouseLeave={() => {
                  // close magnifier
                  setShowMagnifier(false);
                }}
                alt="Logo"
                id="myimage"
                src={
                  handleWindowResize().isMobile
                    ? ONDC_CONNECT_MOBILE
                    : ONDC_CONNECT
                }
                justifyContent={"center"}
                alignItems="center"
                display={"flex"}
                sx={{
                  width: {
                    xs: "90%",
                    sm: "90%",
                    md: "650px",
                    xl: "650px",
                    lg: "650px",
                  },
                  margin: { xs: "auto", sm: "auto", md: "0", xl: "0", lg: "0" },
                  cursor: "none",
                }}
              />
            </div>
            <Box>
              {!handleWindowResize().isMobile && (
                <Typography
                  textAlign={"right"}
                  fontFamily={"open sans"}
                  sx={{
                    width: { xs: "330px", sm: "650px" },
                    marginLeft: { xs: "20px", sm: "5px" },
                    lineHeight: "16.8px",
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#747474",
                    marginTop: "10px",
                  }}
                >
                  The Brand logos used are for representation purpose only.
                </Typography>
              )}
            </Box>
          </Box>

          {handleWindowResize().isDesktop && (
            <div
              style={{
                display: showMagnifier ? "" : "none",
                position: "absolute",
                cursor: "none",
                width: "130px",
                height: "130px",
                border: "3px solid #000",
                borderRadius: "50%",
                // prevent magnifier blocks the mousemove event of img
                pointerEvents: "none",
                // set size of magnifier
                // move element center to cursor pos
                top: `${glassPosY}px`,
                left: `${glassPosX}px`,

                opacity: "1", // reduce opacity so you can verify position
                border: "1px solid lightgray",
                backgroundColor: "white",
                backgroundImage: `url('${UI_KIT.new_web}')`,
                backgroundRepeat: "no-repeat",

                //calculate zoomed image size
                backgroundSize: `${imgWidth * zoomLevel}px ${
                  imgHeight * zoomLevel
                }px`,

                //calculate position of zoomed image.
                backgroundPositionX: `${-x * zoomLevel + magnifieWidth / 1}px`,
                backgroundPositionY: `${
                  -y * zoomLevel + magnifierHeight / 1
                }px`,
              }}
            ></div>
          )}
        </Box>
      </Box>

      <BusinessCategories />
      <BusinessTypes />
      <ONDC />
      <HowONDCWorks />
      <WhyChoose />

      <Footer />
    </Box>
  );
}
