export const CATEGORIES = [
  { title: "Kirana & Grocery", src: "kirana" },
  { title: "Baking & Confectionery", src: "bnc" },
  { title: "Electronics", src: "electronics" },
  { title: "Restaurant & Cafe", src: "rnc" },
  { title: "Fashion", src: "fashion" },
];

export const FEATURES = [
  {
    title:
      "Among the early movers in India to enable ONDC integration as a Technology Service Provider.",
  },
  {
    title: `As a designated Technology Service Provider (TSP) by ONDC, NowFloats offers plug & play solutions with platforms such as Shopify, Magento, WooCommerce, etc.`,
  },
  {
    title:
      "Enabling businesses to register on ONDC and sell on multiple buyer apps like Paytm, Kotak, IDFC, Mystore, etc., or other buyer side apps that will be integrated with ONDC.",
  },
  {
    title:
      "NowFloats ONDC is onboarding businesses and enterprises via its two brands—Zadinga for retailers and SMBs, and Kitproxy for enterprise retail brands, D2C businesses, regional brands (single or multiple stores) and seller aggregators.",
  },
];

export const BUSINESS_CATEGORIES = [
  { title: "Kirana & Grocery", id: "Kirana & Grocery" },
  { title: "Baking & Confectionery", id: "Baking & Confectionery" },
  { title: "Electronics", id: "Electronics" },
  { title: "Restaurant & Cafe", id: "Restaurant & Cafe" },
  { title: "Fashion", id: "Fashion" },
  { title: "Other", id: "Other" },
];

export const BUSINESS_TYPE = [
  {
    title: "Omni Channel Retail Businesses",
    id: "Omni Channel Retail Businesses",
  },
  { title: "Enterprise Online Commerce", id: "Enterprise Online Commerce" },
  { title: "D2C business", id: "D2C business" },
  {
    title: "Regional brand (single & multiple stores)",
    id: "Regional brand (single & multiple stores)",
  },
  { title: "Seller Aggregators", id: "Seller Aggregators" },
  { title: "SMB & MSME Retail Business", id: "SMB & MSME Retail Business" },
  { title: "Other", id: "Other" },
  
];
