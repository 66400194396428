import { Box, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import { Link } from "react-router-dom";
import theme from "../configs/theme";
import ConnectForm from "./connectForm";
import { CustomButton2 } from "./CustomButton2";
import "./footer.css";
import { handleWindowResize } from "./utils";
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: "#2D2D2D",
      [theme.breakpoints.up("xs")]: {
        height: "200px",
        textAlign: "center",
        justifyContent: "center",
        display: "flex",
        width: window.innerWidth,
        paddingBottom : "20px"
      },
      [theme.breakpoints.up("sm")]: {
        textAlign: "left !important",
        height: "171px",
        justifyContent: "center",
        display: "flex",
        width: window.innerWidth,
      },
      [theme.breakpoints.up("md")]: {
        textAlign: "left !important",
        height: "102px",
        justifyContent: "center",
        display: "flex",
      },
      [theme.breakpoints.up("lg")]: {
        textAlign: "left !important",
        height: "102px",
        justifyContent: "center",
        display: "flex",
      },
      [theme.breakpoints.up("xl")]: {
        textAlign: "left !important",
        height: "102px",
        justifyContent: "center",
        display: "flex",
      },
    },
    content: {
      color: "white",
      fontFamily: "open sans !important",
      [theme.breakpoints.up("xs")]: {
        fontSize: "12px !important",
        lineHeight: "19.2",
        width: "350px !important",
        textAlign: "center",
        padding: "0px 0 10px 0",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "12px !important",
        lineHeight: "16.4px",
        width: "736px !important",
        textAlign: "center",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "14px !important",
        lineHeight: "25.6px",
        width: "590px !important",
        textAlign: "left",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "14px !important",
        lineHeight: "25.6px",
        width: "590px !important",
        textAlign: "left",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "14px !important",
        lineHeight: "25.6px",
        width: "1300px !important",
      },
    },

    BtnContainer: {
      position: "fixed",
      bottom: 0,
      zIndex: 10,
      display: "flex",
      alignItems: "center !important",
      justifyContent: "center",
      width: "inherit",
      background:
        "linear-gradient(180deg, rgba(151, 151, 151, 0) 10.68%, #979797 100%)",
      [theme.breakpoints.up("xs")]: {
        padding: "0 0 0px 0",
        height: "109px",
      },
      [theme.breakpoints.up("sm")]: {
        padding: "0 0 0px 0",
        height: "102px",
      },
      [theme.breakpoints.up("md")]: {
        padding: "0 0 0px 0",
        height: "102px",
      },
    },
    customBtn: {
      [theme.breakpoints.up("xs")]: {
        width: "296px",
        textAlign: "center",
        height: "40px !important",
        marginTop: "40px !important",
      },
      [theme.breakpoints.up("sm")]: {
        width: "684px !important",
        textAlign: "center",
        height: "50px !important",
      },
    },
  })
);

export function Footer() {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles(theme);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box className={classes.root}>
      <Box sx={{ maxWidth: "1300px", margin: "auto" }}>
        <ConnectForm handleClose={handleClose} open={open} />
        <Typography
          className={classes.content}
          // sx={{ textAlign: { xs: "center !important", sm: "left" } }}
        >
          NowFloats Technologies Ltd. is not a part of ondc.org. It is a
          Official Technology Service Provider approved by ONDC ( see &nbsp;
          <a
            href="https://ondc.org/network-participants/"
            target="_blank"
            style={{ color: "#FFB900" }}
          >
            here
          </a>{" "}
          )&nbsp; that is enabling businesses to become ONDC-enabled.
        </Typography>
        <Typography style={{ textAlign: "center", fontSize: "14px" }}>
          <Link to={"/caution-notice"} style={{ color: "#FFB900" }}>
            Caution Notice
          </Link>
        </Typography>{" "}
      </Box>
      {!handleWindowResize().isDesktop && (
        <Box className={classes.BtnContainer}>
          <CustomButton2
            onClick={handleOpen}
            title="Get a free demo"
            containerStyle={classes.customBtn}
          />
        </Box>
      )}
    </Box>
  );
}
